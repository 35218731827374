<template>
  <div class="toast" :class="`toast-${type}`">
    <slot />
  </div>
</template>

<script setup lang="ts">
defineProps<{
  type: string;
}>();
</script>

<style scoped>
.toast {
  @apply block w-[15rem] mb-2 px-6 py-2;
  @apply text-white rounded-md;
}

.toast-success {
  @apply bg-blue-500;
}

.toast-error {
  @apply bg-red-500;
}

.toast-warn {
  @apply bg-orange-400;
}
</style>
