<template>
  <div class="fixed top-0 left-0 w-full z-50 h-full flex
    justify-center items-center bg-opacity-60 bg-gray-900"
    v-if="activeDialog">

    <Loader v-if="activeDialog.kind === 'loader'" />
    <AlertDialog v-if="activeDialog.kind === 'alert'"
      :title="activeDialog.title"
      :text="activeDialog.message" />

    <ConfirmDialog v-if="activeDialog.kind === 'confirmation'"
      :title="activeDialog.title"
      :text="activeDialog.message"
      :confirmation-id="activeDialog.id" />

  </div>

  <div class="fixed z-50 bottom-6 right-6 pointer-events-none flex flex-col-reverse">
    <TransitionGroup name="toast-list">
      <Toast v-for="t in activeToasts" :type="t.type" :key="t.id">
        {{t.text}}
      </Toast>
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts">
import {activeDialog, activeToasts, toast} from "@/dialog";
import Loader from "./Loader.vue";
import AlertDialog from "./AlertDialog.vue";
import Toast from "./Toast.vue";
import ConfirmDialog from "./ConfirmDialog.vue";
</script>

<style scoped>
.toast-list-enter-active,
.toast-list-leave-active,
.toast-list-move {
  transition: all 0.5s ease;
}

.toast-list-leave-active {
  position: absolute;
}

.toast-list-enter-from,
.toast-list-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
</style>
